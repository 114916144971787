<template>
  <div class="container">
    <BaseModal
        name="modal_integrations_warning"
        :title=" $t('seller.note_center.text_2599')"
        size="md"
    >
      <b-row class="content-modal">
        <b-col cols="12" md="12" class="mb-3 d-flex flex-column">
          <p class="">{{ $t('seller.note_center.text_318') }}</p>
          <br>
          <p class="">{{ $t('seller.note_center.text_319') }}</p>
        </b-col>
      </b-row>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-3" @click="hideModalAddIntegrationWarning" > {{ $t('seller.note_center.text_2531') }} </BaseButton>
      </template>
    </BaseModal>

    <BaseHeader :title="$t('views.seller.note_center.text_1478')"> </BaseHeader>
    <div class="container-table">
      <div class="box-total">
        <p class="total-vendas info">
          ⚡️ {{ itens }} {{ $t("views.seller.note_center.text_1479") }}
        </p>
        <div>
          <p class="total-vendas">
            {{ $t("views.seller.note_center.text_1480") }}
            <span>R$ {{ total ? formatMoney(total) : "0,00" }}</span>
          </p>
        </div>
        <div></div>
      </div>
      <div class="header-table">
        <div class="flex-sale">
          <div class="d-flex align-items-center mr-4">
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openFilters"
              ><img src="@/assets/img/icons/filtro.svg"
            />
            <span>{{ $t("views.seller.sales.text_1428") }}</span>
            </b-button>
            <b-button
              class="btn-table"
              @click="openModalConfiguration"
              ><img src="@/assets/img/icons/config.svg" width="20"
            />
            <span>{{ $t("views.seller.note_center.text_1255") }}</span>
          </b-button>
          </div>

          <div class="d-flex align-items-center flex-acoes">
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchNotes"
                v-model="dateRange"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  :title="$t('views.seller.note_center.text_1237')"
                >
                  {{ $t("views.seller.note_center.text_1238") }}
                </template>
              </date-range-picker>
            </div>
          </div>
        </div>
        <b-col cols="12" md="4" class="container-pesquisa mt-2">
          <div class="inputSearch">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              type="text"
              :placeholder="$t('views.seller.note_center.text_1483')"
              v-on:input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>

        <b-col class="text-right d-none d-md-block">
          <button @click="openModalAddIntegration" class="btn-add-integration">
            {{ $t("views.seller.note_center.text_1239") }}
          </button>
        </b-col>
      </div>
      <!-- buttons -->
      <b-row
        v-if="item_check.length > 0"
        class="btn-action-items-selected-animation mt-3"
      >
        <b-button
          v-b-tooltip="{
            title:
              allItensChecked === false
                ? $t('views.seller.note_center.text_1484') +
                  ` ${$t(
                    'views.seller.note_center.text_1486'
                  )}  ${total_items_to_select}  ${$t(
                    'views.seller.note_center.text_1487'
                  )}`
                : $t('views.seller.note_center.text_1485') +
                  ` ${$t(
                    'views.seller.note_center.text_1486'
                  )}  ${total_items_to_select}  ${$t(
                    'views.seller.note_center.text_1487'
                  )}`,
            placement: 'bottom',
          }"
          variant="outline-primary"
          style="background: rgba(33, 51, 210, 0.1) !important"
          class="btn-table"
          @click="itemsToSelect"
        >
          <img src="@/assets/img/icons/checkItem.svg" alt="" />
        </b-button>

        <b-button
          v-b-tooltip="{
            title: $t('views.seller.note_center.text_1246'),
            placement: 'bottom',
          }"
          style="background: rgba(237, 237, 240, 0.5) !important"
          class="btn-table"
          @click="handleClickEmittedExternal"
        >
          <img src="@/assets/img/icons/twoRow.svg" alt="" />
        </b-button>

        <b-button
          v-b-tooltip="{
            title: $t('views.seller.note_center.text_1488'),
            placement: 'bottom',
          }"
          class="btn-table"
          style="background: rgba(237, 237, 240, 0.5) !important"
          @click="emitirManual"
        >
          <img src="@/assets/img/icons/extra-modal.svg" alt="" />
        </b-button>

        <b-button
          @click="exportNote"
          v-b-tooltip="{
            title: $t('views.seller.note_center.text_1489'),
            placement: 'bottom',
          }"
          class="btn-table ml-3"
        >
          <img
            src="@/assets/img/icons/download.svg"
            alt=""
            class="d-inline-block"
          />
        </b-button>
      </b-row>
      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="0" class="d-none d-md-block pr-3 pl-3">
          <b-form-checkbox-group
            id="checkbox-group-all"
            class="input-check"
            name="flavour-all"
            v-model="item_check2"
            @change="clickCheckAll"
          >
            <b-form-checkbox value="xx"></b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
        <b-col cols="1" class="d-none d-md-block mr-2 ml-2">
          {{ $t("views.seller.note_center.text_1490") }}
        </b-col>
        <b-col cols="5" md="2" class="">
          {{ $t("views.seller.note_center.text_1491") }}
        </b-col>
        <b-col cols="4" md="2">
          {{ $t("views.seller.note_center.text_1492") }}
        </b-col>
        <b-col cols="3" class="d-none d-md-block"
          >{{ $t("views.seller.note_center.text_1493") }}
        </b-col>
        <b-col cols="2" class="d-none d-md-block"
          >{{ $t("views.seller.note_center.text_1494") }}
        </b-col>
        <b-col cols="1" md="1" class="text-center">
          {{ $t("views.seller.note_center.text_1495") }}
        </b-col>
      </b-row>

      <!-- BODY -->
      <template v-if="!loading && notes.length > 0">
        <b-row class="Table-body" v-for="item in notes" :key="item.id">
          <b-col cols="0" class="d-none d-md-block pl-3 pb-2 mr-4">
            <b-form-checkbox
              :value="item.id"
              :disabled="
                item.fiscal_centers_status !== 'NOT_ISSUED' &&
                  item.fiscal_centers_status !== 'ERROR'
              "
              :id="`checkbox-group-` + item.id"
              :name="`flavour-` + item.id"
              @change="clickCheck"
              v-model="item_check"
            ></b-form-checkbox>
          </b-col>
          <b-col
            cols="1"
            class="d-none d-md-block status-item ml-2"
            :class="getStatus(item.fiscal_centers_status).class"
            v-b-tooltip="{
              title: getStatus(item.fiscal_centers_status).text,
              placement: 'bottom',
            }"
          >
            <span>{{ item.id }}</span>
          </b-col>
          <template>
            <b-col cols="5" md="2" class="">
              {{ item.fiscal_centers_created_at | datetime }}
            </b-col>
          </template>
          <template>
            <b-col
              cols="4"
              md="2"
              class=""
              v-b-tooltip="{
                title: item.product,
                placement: 'top',
              }"
            >
              {{
                item.product.length > maxLength
                  ? item.product.substr(0, maxLength) + "..."
                  : item.product
              }}
            </b-col>
          </template>

          <b-col
            cols="3"
            class="d-none d-md-block dados-cliente"
            v-b-tooltip="{
              title: item.name,
              placement: 'top',
            }"
          >
            {{
              item.name.length > maxLength
                ? item.name.substr(0, maxLength) + "..."
                : item.name
            }}
          </b-col>

          <b-col cols="2" class="d-none d-md-block">
            <span class="flex">
              <font-awesome-icon
                v-if="item.method != 'TWO_CREDIT_CARDS' && item.local_currency_id == 1"
                :icon="getIconMethod(item.method, item.local_currency_id)"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <div 
                v-else-if="item.method != 'TWO_CREDIT_CARDS' && item.local_currency_id != 1"
                :id="`method-icon-${item.id}`"
                class="Table-icon-international"
              >
                <img src="@/assets/img/icons/international.svg" />
              </div>
              <b-tooltip
                :target="`method-icon-${item.id}`"
                :title="getMethod(item.method, item.local_currency_id)"
                placement="left"
              />
              <!-- Two Credit Cards -->
              <font-awesome-icon
                v-if="item.method == 'TWO_CREDIT_CARDS'"
                icon="credit-card"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <font-awesome-icon
                v-if="item.method == 'TWO_CREDIT_CARDS'"
                icon="credit-card"
                class="icon-gray TWO_CREDIT_CARDS"
              />
              <!-- Bump -->
              <font-awesome-icon
                v-if="item.bump_id"
                icon="donate"
                class="icon-gray ml-2"
                :id="`bump-sale-${item.id}`"
              />
              <b-tooltip
                v-if="item.bump_id"
                :target="`bump-sale-${item.id}`"
                title="ORDER BUMP"
                placement="left"
              />
              <!-- Coupom -->
              <font-awesome-icon
                v-if="item.coupon_id"
                icon="receipt"
                class="icon-gray ml-2"
                :id="`cupom-sale-${item.id}`"
              />
              <b-tooltip
                v-if="item.coupon_id"
                :target="`cupom-sale-${item.id}`"
                :title="$t('views.seller.sales.text_1425')"
                placement="left"
              />
            </span>
            <span v-if="item.total" class="valor-venda">
              {{ currency.currency_symbol }} {{ formatMoney(+item.total) }}
            </span>
            <span v-else class="valor-venda">
              {{ currency.currency_symbol }} 0,00
            </span>
          </b-col>

          <b-col cols="2" md="1">
            <div class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="notes.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="(loading && !notes.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !notes.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">
          {{ $t("views.seller.note_center.text_1240") }}
        </p>
      </b-row>

      <FiltersModal @filter="filter" />
      <NoteCenterPreview
        ref="noteCenterPreview"
        @externalIssue="externalIssueByPreview"
        :updateQueryParams="setQueryParams"
      />
      <Configuration
        :integrations="integrations"
        :idIntegrationToDefault="idIntegrationToDefault"
        :integrationDefault="integrationDefault"
        :nameIntegrationsSelected="nameIntegrationsSelected"
        :user="user"
        :dataConfigurationOfPaidAt="dataConfigurationOfPaidAt"
        @selectIntegration="openModalIntegrations"
        @changePersonIssuer="changePersonIssuer"
      />
      <AddIntegration />
      <ListIntegration
        :integrations="integrations"
        @integrationSelected="integrationSelected"
        :modalToReturn="modalToReturn"
        :user="user"
        :dataConfigurationOfPaidAt="dataConfigurationOfPaidAt"
      />
    </div>
    <CallEvaluation />
  </div>
</template>
<script>
import Paginate from "@/components/shared/Paginate";
import FiltersModal from "@/components/Seller/NoteCenter/modals/Filters";
import Configuration from "@/components/Seller/NoteCenter/modals/Configuration";
import DateRangePicker from "vue2-daterange-picker";
import Money from "@/mixins/money";
import moment from "moment-timezone";
import _ from "lodash";
import NoteCenter from "@/services/resources/NoteCenter";
import NoteCenterPreview from "@/components/Seller/NoteCenter/NoteCenterPreview.vue";
import Vue from "vue";
import AddIntegration from "@/components/Seller/NoteCenter/modals/AddIntegration";
import ListIntegration from "@/components/Seller/NoteCenter/modals/ListIntegration";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";
import PermissionRedirect from "../../services/PermissionRedirect";
import { iconMethod } from "@/helpers.js";
import { textMethod } from "@/helpers.js";
import BaseModal from "@/components/BaseModal";
const noteCenterIntegrationApi = NoteCenterIntegration.build();
const apiNoteCenter = NoteCenter.build();

export default {
  name: "ListagemVendas",
  components: {
    Paginate,
    FiltersModal,
    DateRangePicker,
    NoteCenterPreview,
    Configuration,
    AddIntegration,
    ListIntegration,
  },
  data() {
    return {
      idIntegrationToDefault: null,
      integrationDefault: {},
      integrations: {},
      modalToReturn: "",
      initExport: false,
      notes: [],
      note_id: -1,
      search: "",
      loading: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {},
      type_date: "created_at",
      types_list: [
        {
          value: "created_at",
          text: this.$t("views.seller.note_center.text_1496"),
        },
        {
          value: "paid_at",
          text: this.$t("views.seller.note_center.text_1497"),
        },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      currency: {
        currency_symbol: "R$",
      },
      client: {
        width: 0,
      },
      item_check: [],
      item_check2: [],
      selected_action: [],
      activeBtnExternallyIssued: true,
      total_items_to_select: 0,
      allItensChecked: false,
      emitManual: false,
      nameIntegrationsSelected: "",
      user: {
        documents: [],
        company: {
          documents: [],
        },
      },
      dataConfigurationOfPaidAt: {},
      selectedAll: false,
      sendEmit: false,
    };
  },
  mixins: [Money],
  comments: {
    BaseModal,
  },
  computed: {
    active() {
      return this.$route.name;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t("locale_date.today")]: [today, today],
        [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
        [this.$t("locale_date.last_week")]: [lastWeek, today],
        [this.$t("locale_date.current_month")]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t("locale_date.current_year")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("locale_date.last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t("locale_date.every_period")]: [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    getIconMethod(method, currency_id) {
      return iconMethod(method, currency_id);
    },
    getMethod(method, currency_id) {
      return textMethod(method, currency_id);
    },
    changePersonIssuer(change) {
      this.dataConfigurationOfPaidAt.issue_by_legal_entity = change;
    },
    configurationOfPaidAt() {
      apiNoteCenter
        .get("configuration-paid")
        .then((response) => {
          this.dataConfigurationOfPaidAt = response;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    emitirManual() {
      let isInternational = false
      this.notes.forEach((item, index) => {
        if (this.item_check.find((id) => id == item.id)) {
          if(this.notes[index].local_currency_id !== 1){
            isInternational= true;
          }
        }
      });

      if(!isInternational){
        this.configurationOfPaidAt();
        this.$bvModal.show("modal_integrations");
        this.getIntegrations();
        this.emitManual = true;
      }else{
        this.$bvModal.show("modal_integrations_warning");
      }
    },
    hideModalAddIntegrationWarning(){
      this.$bvModal.hide("modal_integrations_warning");
    },
    integrationSelected(integration) {
      if (this.emitManual) {
        let auxText = this.item_check.length > 1 ? "s" : "";
        Vue.swal({
          title: this.$t("views.seller.note_center.text_1241"),
          text: `${this.$t("views.seller.note_center.text_1242")} ${
            this.item_check.length
          } ${this.$t(
            "views.seller.note_center.text_1498"
          )}${auxText} ${this.$t("views.seller.note_center.text_1499")} ${
            integration.name
          }`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "views.seller.note_center.text_1243"
          )}${auxText}`,
          cancelButtonText: this.$t("views.seller.note_center.text_1500"),
          customClass: "sweet-container",
          confirmButtonClass: "button button-black mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let itemCheck = this.item_check;
            this.item_check = [];
            this.item_check2 = [];

            if (this.selectedAll) {
              this.sendEmit = true;
            } else {
              this.sendEmit = false;
            }

            this.notes.forEach((item, index) => {
              if (itemCheck.find((id) => id == item.id)) {
                this.notes[index].fiscal_centers_status = "PROCESSING";
              }
            });

            this.messageSuccess(
              `${this.$t("views.seller.note_center.text_1244")} ${
                itemCheck.length
              }
             ${this.$t("views.seller.note_center.text_1498")}` +
                auxText +
                ` ${this.$t("views.seller.note_center.text_1501")} ` +
                integration.name,
              `${this.$t("views.seller.note_center.text_1502")}` + auxText
            );

            apiNoteCenter
              .post("manual-emit", {
                notes: itemCheck,
                integration_id: integration.id,
              })
              .then((d) => {})
              .catch((e) => console.log(e));

            setTimeout(() => {
              this.getFiltersSalved();
            }, 1000);

            this.loading = false;
          } else {
            this.emitirManual();
          }
        });
      } else {
        this.integrationDefault = integration;
        this.idIntegrationToDefault = integration.id;
        this.nameIntegrationsSelected = integration.name;
      }
    },
    messageSuccess(msg, title) {
      this.$bvToast.toast(msg, {
        variant: "info",
        title: title,
        autoHideDelay: 8000,
        appendToast: true,
      });
    },
    openModalIntegrations() {
      this.$bvModal.show("modal_integrations");
      this.$bvModal.hide("modal_configuration");
      this.modalToReturn = "modal_configuration";
    },
    getIntegrations() {
      this.emitManual = false;
      this.modalToReturn = "";
      this.integrationDefault = {};
      this.idIntegrationToDefault = null;
      noteCenterIntegrationApi.get("").then((response) => {
        this.integrations = response;
        response.forEach((item) => {
          if (item.default) {
            this.integrationDefault = item;
            this.idIntegrationToDefault = item.id;
          }
        });
      });
    },
    openModalAddIntegration() {
      this.$bvModal.show("modal_add_integration");
    },
    handleClickEmittedExternal() {
      let auxString = this.item_check.length > 1 ? "s" : "";
      let emitidaExternamente =
        this.item_check.length > 1
          ? this.$t("views.seller.note_center.text_1503")
          : this.$t("views.seller.note_center.text_1246");
      let marcarNotaEmitidaExternamente =
        this.item_check.length > 1
          ? this.$t("views.seller.note_center.text_1504")
          : this.$t("views.seller.note_center.text_1245");
      Vue.swal({
        title: emitidaExternamente,
        text: `${marcarNotaEmitidaExternamente} ${
          this.item_check.length
        } ${this.$t("views.seller.note_center.text_1498")}${auxString} `,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: emitidaExternamente,
        cancelButtonText: this.$t("views.seller.note_center.text_1500"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.externalIssue();
        }
      });
    },
    openModalConfiguration() {
      this.getIntegrations();
      this.$bvModal.show("modal_configuration");
      this.nameIntegrationsSelected = "";
    },
    exportNote() {
      this.$bvToast.toast(this.$t("views.seller.note_center.text_1247"), {
        variant: "info",
        title: this.$t("views.seller.note_center.text_1505"),
        autoHideDelay: 8000,
        appendToast: true,
      });
      apiNoteCenter
        .search({ url: "export", note: this.item_check })
        .then((response) => {
          let linkSource =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            response.data;
          let downloadLink = document.createElement("a");
          let date = new Date();
          let fileName = "fiscal_note_" + date.getTime() + ".xlsx";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.download();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    itemsToSelect() {
      if (this.allItensChecked) {
        this.allItensChecked = false;
        this.item_check2 = [];
        this.selectedAll = false;
        return (this.item_check = []);
      }
      this.selectedAll = true;
      let data = {};
      data.client = this.search;
      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);
      if (this.dateRange.startDate && this.dateRange.endDate) {
        data.date_start = moment(this.dateRange.startDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }
      apiNoteCenter
        .search({
          url: "/items-to-select",
          ...data,
        })
        .then((response) => {
          this.allItensChecked = true;
          this.item_check = response;
          this.item_check2 = ["xx"];
          this.$bvToast.toast(
            `${this.$t("views.seller.note_center.text_1248")} ${
              this.item_check.length
            } ${this.$t("views.seller.note_center.text_1487")}`,
            {
              title: this.$t("views.seller.note_center.text_1490"),
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    externalIssueByPreview(noteId) {
      this.getFiltersSalved();
      this.item_check = this.item_check.map((item) => {
        if (item != noteId) {
          return item;
        }
      });
    },
    externalIssue() {
      this.loading = true;
      apiNoteCenter
        .post("externally-issued", { notes: this.item_check })
        .then(() => {
          this.fetchNotes();
          this.$bvToast.toast(
            `${this.item_check.length} ${this.$t(
              "views.seller.note_center.text_1490"
            )}${this.item_check.length > 1 ? "s" : ""}
        ${this.$t("views.seller.note_center.text_1249")}`,
            {
              title: this.$t("views.seller.note_center.text_1490"),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          document.querySelector("body").removeAttribute("style");
          this.item_check = [];
          this.item_check2 = [];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickCheckAll(event) {
      if (event.length === 0) {
        this.item_check = [];
        this.item_check2 = [];
        return;
      }
      let notes = this.notes;
      let array = [];
      for (let i = 0; i < notes.length; i++) {
        if (notes[i].fiscal_centers_status === "NOT_ISSUED") {
          const element = notes[i].id;
          array.push(element);
        }
      }
      this.item_check = array;
    },
    clickCheck() {
      this.item_check2 = [];
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status) {
          case "ISSUED": {
            r = {
              text: this.$t("views.seller.note_center.text_1250"),
              class: "is-green",
            };
            break;
          }
          case "NOT_ISSUED": {
            r = {
              text: this.$t("views.seller.note_center.text_1251"),
              class: "is-warning",
            };
            break;
          }
          case "ISSUED_EXTERNALLY": {
            r = {
              text: this.$t("views.seller.note_center.text_1252"),
              class: "is-light-blue",
            };
            break;
          }
          case "ERROR": {
            r = {
              text: this.$t("views.seller.note_center.text_1253"),
              class: "is-red",
            };
            break;
          }
          case "PROCESSING": {
            r = {
              text: this.$t("views.seller.note_center.text_1506"),
              class: "is-black",
            };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changedType() {
      this.fetchNotes();
    },
    debounce: _.debounce(function() {
      this.pagination.currentPage = 1;
      this.search = this.search.replace("+", "");
      this.filters.client = this.search;
      this.fetchNotes();
    }, 500),
    openPanel(note_id) {
      this.note_id = note_id;
      this.$router.push({ query: { s_id: this.note_id } }).catch(() => {});
      setTimeout(() => {
        this.$refs.noteCenterPreview.openPanel(this.note_id);
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("notes-filters");
    },
    exportData() {
      this.fetchNotes();
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchNotes();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchNotes();
    },
    filter(filters) {
      this.pagination.currentPage = 1;
      this.filters = filters;
      this.fetchNotes();
    },
    fetchNotes(dados_local = null) {
      if (typeof this.$route.query.s_id == "undefined") {
        this.$router
          .replace({
            query: {
              start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"),
              end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"),
              ...this.filters,
            },
          })
          .catch(() => {});
      }
      this.loading = true;
      let data = {};

      if (dados_local) {
        data = dados_local;
        this.search = data.client ?? "";
      } else {
        data = {
          client: this.search ?? "",
        };
        let notesFiltersAux = localStorage.getItem("notes_filters_aux");
        if (!notesFiltersAux) {
          data.status = ["NOT_ISSUED", "PROCESSING", "ERROR"];
        }
      }
      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );

      data = Object.assign(data, this.filters);
      if (this.dateRange.startDate && this.dateRange.endDate) {
        data.date_start = moment(this.dateRange.startDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      apiNoteCenter
        .search({
          ...data,
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
        })
        .then((response) => {
          this.saveSearch(data);
          this.notes = response.data.map((note) => {
            if (this.selectedAll && this.sendEmit) {
              if (note.fiscal_centers_status === "NOT_ISSUED") {
                note.fiscal_centers_status = "PROCESSING";
              }
            }
            return note;
          });
          this.pagination.totalPages = response.last_page;
          this.total = response.total_amount;
          this.itens = response.total;
          this.total_items_to_select = response.total_items_to_select;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    download() {
      this.$bvToast.toast(this.$t("views.seller.note_center.text_1254"), {
        variant: "success",
        title: this.$t("views.seller.note_center.text_1505"),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    searchByFilter(dados_filter) {
      this.dateRange.startDate = this.decodeDateFilter(dados_filter.date_start);
      this.dateRange.endDate = this.decodeDateFilter(dados_filter.date_end);
      this.fetchNotes(dados_filter);
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case this.$t("locale_date.today_lowercase"): {
          return moment();
        }
        // ontem
        case $t("locale_date.yesterday"): {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case this.$t("locale_date.one_week"): {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case $t("locale_date.first_day_current_month"): {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case this.$t("locale_date.last_day_current_month"): {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case this.$t("locale_date.first_day_year"): {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case this.$t("locale_date.last_day_year"): {
          return moment().endOf("year");
        }

        // primeiro dia do mes passado
        case this.$t("locale_date.first_day_last_month"): {
          return moment()
            .startOf("month")
            .subtract(1, "month");
        }
        // ultimo dia do mes passado
        case this.$t("locale_date.last_day_last_month"):
          return moment()
            .endOf("month")
            .subtract(1, "month")
            .add(1, "days");
        default: {
          return date;
        }
      }
    },
    saveSearch(dados_pesquisa) {
      window.localStorage.notes_filters = {};
      dados_pesquisa.date_start = this.formatDateFilter(
        dados_pesquisa.date_start
      );
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end);
      window.localStorage.notes_filters = JSON.stringify(dados_pesquisa);
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return this.$t("locale_date.today");
        }
        // ontem
        case moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.yesterday");
        }
        // uma semana atras
        case moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.one_week");
        }
        // primeiro dia do mes atual
        case moment()
          .startOf("month")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.first_day_current_month");
        }
        // ultimo dia do mes atual
        case moment()
          .endOf("month")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.last_day_current_month");
        }
        // primeiro dia do ANO atual
        case moment()
          .startOf("year")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.first_day_year");
        }
        // ultimo dia do ANO atual
        case moment()
          .endOf("year")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.last_day_year");
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.first_day_last_month");
        }
        // ultimo dia do mes passado
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.last_day_last_month");
        }
        default: {
          return date;
        }
      }
    },
    getFiltersSalved() {
      // filtros salvos
      if (window.localStorage.notes_filters) {
        this.filters = JSON.parse(window.localStorage.notes_filters);
        this.filters.date_start = this.$route.query.start_date || moment();
        this.filters.date_end = this.$route.query.end_date || moment();
        this.searchByFilter(this.filters);
      } else {
        this.dateRange.startDate = this.$route.query.start_date || moment();
        this.dateRange.endDate = this.$route.query.end_date || moment();
        this.fetchNotes();
      }
    },
    setQueryParams() {
      this.dateRange.startDate =
        this.$route.query.start_date || this.dateRange.startDate || moment();
      this.dateRange.endDate =
        this.$route.query.end_date || this.dateRange.endDate || moment();
      this.filters.status =
        this.$route.query.status || this.filters.status || "";
      this.filters.products =
        this.$route.query.products || this.filters.products || "";
      this.fetchNotes();
    },
  },
  created() {
    this.user = this.$store.state.user.user;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    PermissionRedirect("NoteRead");
    this.loading = true;
    this.configurationOfPaidAt();
    // this.setQueryParams();
    if (typeof this.$route.query.s_id != "undefined") {
      this.note_id = this.$route.query.s_id;
      setTimeout(() => {
        this.$refs.noteCenterPreview.openPanel(this.note_id);
        this.loading = false;
      }, 1000);
    } else {
      this.setQueryParams();
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.content-modal{
  padding-right: 20%;
  padding-left: 20%;
  text-align: center;
}
.content-modal p{
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.status-item:before {
  left: -14px !important;
  top: 8px !important;
}

.btn-add-integration {
  width: 229px;
  height: 50px;
  color: #fff;
  background: #2a63ab;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.btn-action-items-selected-animation {
  animation: animationactionsbtn linear 0.5s !important;
  animation-iteration-count: 1 !important;
  transform-origin: 50% 50% !important;
  -webkit-animation: animationactionsbtn linear 0.5s !important;
  -webkit-animation-iteration-count: 1 !important;
  -webkit-transform-origin: 50% 50% !important;
  -moz-animation: animationactionsbtn linear 0.5s !important;
  -moz-animation-iteration-count: 1 !important;
  -moz-transform-origin: 50% 50% !important;
  -o-animation: animationactionsbtn linear 0.5s !important;
  -o-animation-iteration-count: 1 !important;
  -o-transform-origin: 50% 50% !important;
  -ms-animation: animationactionsbtn linear 0.5s !important;
  -ms-animation-iteration-count: 1 !important;
  -ms-transform-origin: 50% 50% !important;
}

@keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes animationactionsbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}

.btn-table {
  width: max-content;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #F5F5F5;
  padding: 16px;
  outline: none;
}

.btn-table span {
  color: #091E3E;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
}

.valor-venda {
  margin-left: 10px;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2),
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.whats-cliente {
  font-size: 13px;
  color: #002363;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
</style>
